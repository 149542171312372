<template>
	<div class="mb-12">
		<div class="md:flex md:items-center md:justify-between mb-5">
			<div class="flex-1 min-w-0">
				<h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
					Main Navigation
				</h2>
			</div>
		</div>

		<div class="space-y-12">
			<div>
				<div class="pb-5 border-b border-sc-green sm:flex sm:items-center sm:justify-between">
					<h3 class="text-xl leading-6 font-semibold text-sc-green">
						Health
					</h3>
					<div class="mt-3 sm:mt-0 sm:ml-4">
						<button
							type="button"
							class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
							@click="createMenuItem('health')"
						>
							Create Health Menu Item
						</button>
					</div>
				</div>

				<draggable
					v-model="menu.health"
					handle=".handle"
					tag="div"
					item-key="name"
					class="mt-5 bg-white divide-y divide-gray-200"
					@change="itemMoved(evt, 'health')"
				>
					<template #item="{ element }">
						<div class="flex gap-x-5 items-center px-5 hover:bg-blue-50">
							<div class="flex-1 font-medium">
								{{ element.name }}
							</div>
							<div class="flex-1 text-gray-500">
								{{ pageUrl(element) }}
							</div>
							<div
								class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-x-8"
							>
								<button
									type="button"
									class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-600 hover:bg-red-200 focus:outline-none"
									@click="confirmDelete(element, 'health')"
								>
									<XIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<button
									type="button"
									class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-yellow-200 text-yellow-600 hover:bg-yellow-300 focus:outline-none"
									@click="editMainMenuItem(element, 'health')"
								>
									<PencilIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<div class="flex-none flex justify-end items-center">
									<SwitchVerticalIcon class="h-5 w-5 text-gray-400 handle" aria-hidden="true" />
								</div>
							</div>
						</div>
					</template>
				</draggable>
			</div>

			<div>
				<div class="pb-5 border-b border-sc-green sm:flex sm:items-center sm:justify-between">
					<h3 class="text-xl leading-6 font-semibold text-sc-green">
						Value
					</h3>
					<div class="mt-3 sm:mt-0 sm:ml-4">
						<button
							type="button"
							class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
							@click="createMenuItem('value')"
						>
							Create Value Menu Item
						</button>
					</div>
				</div>

				<draggable
					v-model="menu.value"
					handle=".handle"
					tag="div"
					item-key="name"
					class="mt-5 bg-white divide-y divide-gray-200"
					@change="itemMoved(evt, 'value')"
				>
					<template #item="{ element }">
						<div class="flex gap-x-5 items-center px-5 hover:bg-blue-50">
							<div class="flex-1 font-medium">
								{{ element.name }}
							</div>
							<div class="flex-1 text-gray-500">
								{{ pageUrl(element) }}
							</div>
							<div
								class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-x-8"
							>
								<button
									type="button"
									class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-600 hover:bg-red-200 focus:outline-none"
									@click="confirmDelete(element, 'value')"
								>
									<XIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<button
									type="button"
									class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-yellow-200 text-yellow-600 hover:bg-yellow-300 focus:outline-none"
									@click="editMainMenuItem(element, 'value')"
								>
									<PencilIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<div class="flex-none flex justify-end items-center">
									<SwitchVerticalIcon class="h-5 w-5 text-gray-400 handle" aria-hidden="true" />
								</div>
							</div>
						</div>
					</template>
				</draggable>
			</div>

			<div>
				<div class="pb-5 border-b border-sc-green sm:flex sm:items-center sm:justify-between">
					<h3 class="text-xl leading-6 font-semibold text-sc-green">
						Community
					</h3>
					<div class="mt-3 sm:mt-0 sm:ml-4">
						<button
							type="button"
							class="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
							@click="createMenuItem('community')"
						>
							Create Community Menu Item
						</button>
					</div>
				</div>

				<draggable
					v-model="menu.community"
					handle=".handle"
					tag="div"
					item-key="name"
					class="mt-5 bg-white divide-y divide-gray-200"
					@change="itemMoved(evt, 'community')"
				>
					<template #item="{ element }">
						<div class="flex gap-x-5 items-center px-5 hover:bg-blue-50">
							<div class="flex-1 font-medium">
								{{ element.name }}
							</div>
							<div class="flex-1 text-gray-500">
								{{ pageUrl(element) }}
							</div>
							<div
								class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center gap-x-8"
							>
								<button
									type="button"
									class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-red-100 text-red-600 hover:bg-red-200 focus:outline-none"
									@click="confirmDelete(element, 'community')"
								>
									<XIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<button
									type="button"
									class="relative inline-flex items-center p-1 border border-transparent shadow-sm font-medium rounded-md bg-yellow-200 text-yellow-600 hover:bg-yellow-300 focus:outline-none"
									@click="editMainMenuItem(element, 'community')"
								>
									<PencilIcon class="h-5 w-5" aria-hidden="true" />
								</button>
								<div class="flex-none flex justify-end items-center">
									<SwitchVerticalIcon class="h-5 w-5 text-gray-400 handle" aria-hidden="true" />
								</div>
							</div>
						</div>
					</template>
				</draggable>
			</div>
		</div>

		<ModalsConfirmation
			ref="confirmation"
			:open="confirmationOpen"
			:confirmation-config="confirmationConfig"
			@closeModal="closeModal"
			@confirm="confirm"
		/>

		<SlidePanel
			slide-title="Create Main Menu Item"
			slide-component="MediaMainMenuItemSelect"
			:open="slideOpen"
			:edit="edit"
			@closeSlide="closeSlide"
			@createMainMenuItem="createMainMenuItem"
			@updateMainMenuItem="updateMainMenuItem"
		/>
	</div>
</template>

<script>
import { provide, inject, computed, ref } from 'vue'
import { useToast } from 'vue-toastification'

import draggable from 'vuedraggable'
import {
	SwitchVerticalIcon,
	PencilIcon,
	XIcon,
	// PlusCircleIcon,
	// SaveIcon,
} from '@heroicons/vue/solid'

export default {
	name: 'Hamburger',
	components: {
		draggable,
		SwitchVerticalIcon,
		XIcon,
		PencilIcon,
		// PlusCircleIcon,
		// SaveIcon,
	},
	setup() {
		const api = inject('api')
		const toast = useToast()

		const edit = ref(null)
		provide(
			'edit',
			computed(() => edit.value)
		)

		return {
			api,
			toast,
			edit,
		}
	},
	data() {
		return {
			slideOpen: false,
			confirmationOpen: false,
			confirmationConfig: {},
			creating: '',
			menu: {},
		}
	},
	mounted() {
		this.fetchHamburger()
	},
	methods: {
		fetchHamburger() {
			this.loaderShow()

			this.api.hamburger
				.all()
				.then(hamburger => {
					hamburger.forEach(element => {
						if (!(element.category in this.menu)) {
							this.menu[element.category] = []
						}

						this.menu[element.category].push(element)
					})
				})
				.catch(error => {
					this.toast.error('Error fetching hamburger menu items:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		cancelEdit() {
			this.editing = false
			this.edit = null
		},

		itemMoved(evt, category) {
			let ids = []
			this.menu[category].forEach(element => {
				ids.push(element.id)
			})
			this.loaderShow()

			this.api.hamburger
				.reorder(ids)
				.then(data => {
					// this.fetchHamburger()
					// this.menu[this.creating].push(mainMenuItem)
					// this.creating = ''
				})
				.catch(error => {
					this.toast.error('Error creating new menu item:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		openSlide() {
			this.slideOpen = true
		},

		closeSlide() {
			this.slideOpen = false
			this.creating = ''
			this.edit = null
		},

		editMainMenuItem(mainMenuItem, category) {
			this.creating = category
			this.edit = JSON.parse(JSON.stringify(mainMenuItem))
			this.openSlide()
		},

		createMenuItem(category) {
			this.creating = category
			this.openSlide()
		},

		createMainMenuItem(mainMenuItem) {
			mainMenuItem.category = this.creating
			// console.log(this.creating, mainMenuItem)

			if (!(this.creating in this.menu)) {
				this.menu[this.creating] = []
			}

			this.loaderShow()

			this.api.hamburger
				.store(mainMenuItem)
				.then(data => {
					// console.log(data)
					this.menu[data.category].push(data)
					this.creating = ''

					this.toast.success('Successfully created menu item')
				})
				.catch(error => {
					this.toast.error('Error creating new menu item:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		updateMainMenuItem(mainMenuItem) {
			this.loaderShow()

			this.api.hamburger
				.update(mainMenuItem, mainMenuItem.id)
				.then(hamburger => {
					this.menu = {}
					hamburger.forEach(element => {
						if (!(element.category in this.menu)) {
							this.menu[element.category] = []
						}

						this.menu[element.category].push(element)
					})

					this.toast.success('Successfully updated menu item')
				})
				.catch(error => {
					this.toast.error('Error creating new menu item:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		closeModal() {
			this.confirmationOpen = false
			this.confirmationConfig = {}
			delete this.$refs.confirmation.category
			delete this.$refs.confirmation.id
		},

		confirm(callback) {
			this[callback]()
		},

		confirmDelete(item, category) {
			this.confirmationOpen = true
			this.confirmationConfig = {
				callback: 'deleteMainMenuItem',
				title: 'Delete Main Menu Item',
				content: 'Are you sure you want to delete this main menu item?',
			}
			this.$refs.confirmation.category = category
			this.$refs.confirmation.id = item.id
		},

		deleteMainMenuItem() {
			let id = this.$refs.confirmation.id
			let category = this.$refs.confirmation.category
			let index = this.menu[category].findIndex(x => x.id === id)
			this.loaderShow()

			this.api.hamburger
				.destroy(id)
				.then(data => {
					delete this.$refs.confirmation.id
					delete this.$refs.confirmation.category

					this.menu[category].splice(index, 1)

					this.closeModal()
				})
				.catch(error => {
					this.toast.error('Error deleting menu item:\n' + error.response.data.message)
				})
				.then(() => {
					this.loaderHide()
				})
		},

		pageUrl(element) {
			// console.log(element.page)
			if (element.page !== null) {
				return element.page.path
			} else {
				return element.external
			}
		},
	},
}
</script>
